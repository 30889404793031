const textTimings = [
  {
    text: "Back here LIVE at the waterfront village",
    startTime: 0,
    endTime: 2.75,
  },
  {
    text: "with my friend the zombie, Jonathan",
    startTime: 2.75,
    endTime: 5.21,
  },
  {
    text:
      "You're looking good! Jonathan just got an awesome face-paint job, what do you think?",
    startTime: 5.21,
    endTime: 8.78,
  },
  {
    text: "I LIKE TURTLES!!!",
    startTime: 8.78,
    endTime: 10.18,
  },
  {
    text: "...Alright! You're a great zombie.",
    startTime: 10.18,
    endTime: 12.9,
  },
  {
    text:
      "And good times here at the Waterfront Village, open for the next 11 days ....",
    startTime: 12.9,
    endTime: 17.08,
  },
];

export default textTimings;