import React, { useEffect, useRef, useState } from "react";
import styles from "./Subtitles.module.scss";
import sampleVideo from "./zombie-kid-likes-turtles.mp4";
import textTimings from "./textTimings.js";

const Subtitles = () => {
  const videoRef = useRef(null);
  const [currentSubtitle, setCurrentSubtitle] = useState("");

  useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      const video = videoRef.current;
      const currentTime = video.currentTime;

      // check which subtitle corresponds to the current time
      let nextSubtitle = "";
      for (let i = 0; i < textTimings.length; i += 1) {
        const currentTiming = textTimings[i];
        const { startTime, endTime, text } = currentTiming;
        if (currentTime >= startTime && currentTime < endTime) {
          nextSubtitle = text;
          break;
        }
      }

      // update the subtitle
      setCurrentSubtitle(nextSubtitle);
    };

    video.ontimeupdate = handleTimeUpdate;
  }, []);

  return (
    <div className={styles.container}>
      <h2>Zombie Kid Likes Turtles</h2>
      <div className={styles.videoContainer}>
        <video
          src={sampleVideo}
          controls
          className={styles.video}
          ref={videoRef}
        />
      </div>
      <div className={styles.subtitlesContainer}>{currentSubtitle}</div>
    </div>
  );
};

export default Subtitles;
