import React, { Component } from "react";
import styles from "./GifCreate.module.scss";
import { MAX_LENGTH } from "./GifMaker.jsx";

/* Component representing the GIF creator button
 * assumes the input properties:
 *  - urlList: JavaScript array of image URLs for the component images in the gif
 *  - delay: the delay in seconds between displaying each image
 *  - width: the width of the final GIF
 *  - height: the height of the final GIF
 *  - background_color: The color behind the final GIF
 *  - transparent: 1 if the GIF background is transparent, 0 otherwise
 */
class GifCreateButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gif: undefined,
      loading: false,
    };
  }

  //Call the API to make a GIF from the current images
  createGif = () => {
    const body = {
      urlList: this.props.urlList,
      delay: this.props.delay,
      width: this.props.width,
      height: this.props.height,
      background_color: this.props.background_color,
      transparent: this.props.transparent,
    };

    this.setState({
      loading: true,
      gif: undefined,
    });

    fetch("/api/gif", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            gif: result.gif,
            loading: false,
          });
        },
        (error) => {
          console.log("An error occurred:", error);
          this.setState({
            loading: false,
          });
        }
      );
  };

  renderFinalImage() {
    const { gif } = this.state;
    if (!gif) return null;
    else
      return (
        <>
          <div className={styles.header}>Final gif:</div>
          <img src={gif} alt="Final GIF" className={styles.image} />
        </>
      );
  }

  render() {
    const { loading } = this.state;
    return (
      <div className={styles.container}>
        {loading ? (
          "Loading ..."
        ) : (
          <input type="button" value="Create GIF" onClick={this.createGif} />
        )}
        <div style={{ width: MAX_LENGTH }}>{this.renderFinalImage()}</div>
      </div>
    );
  }
}

export default GifCreateButton;
