import React, { Component } from "react";
import Upload from "components/upload/Upload.jsx";
import GifPreview from "./GifPreview.jsx";
import GifCreate from "./GifCreate.jsx";
import SizeInput from "./inputs/SizeInput.jsx";
import DelayInput from "./inputs/DelayInput.jsx";
import styles from "./GifMaker.module.scss";

const MAX_LENGTH = 700; //maximum supported height/width

//default dimentions for before images are uploaded
const defaultWidth = 400;
const defaultHeight = 400;

class GifMaker extends Component {
  //Keep list of uploaded image URLs
  constructor(props) {
    super(props);
    this.state = {
      urlList: [],
      delay: 1,
      width: defaultWidth,
      height: defaultHeight,
      initWidth: defaultWidth,
      initHeight: defaultHeight,
      background_color: "#FFFFFF",
      transparent: 0,
    };
  }

  //Upon a new upload, update the list of image URLs
  handleFinish = (url) => {
    console.log("upload complete!", url);

    this.setState((state) => ({
      urlList: state.urlList.concat(url),
    }));
  };

  //Set the width and height of the GIF based on the first image, once it is loaded
  setInitDimentions = ({ target: img }) => {
    //Constrain the size of the image
    var newWidth;
    var newHeight;
    if (img.width > img.height) {
      newWidth = Math.min(img.width, MAX_LENGTH);
      newHeight = Math.round((newWidth * img.height) / img.width);
    } else {
      newHeight = Math.min(img.height, MAX_LENGTH);
      newWidth = Math.round((newHeight * img.width) / img.height);
    }

    this.setState({
      width: newWidth,
      height: newHeight,
      initWidth: newWidth,
      initHeight: newHeight,
    });
  };

  //Upon submission of new dimensions from the user
  setNewDimentions = (width, height) => {
    this.setState({
      width: width,
      height: height,
    });
  };

  //reset the delay based on input
  setDelay = (delay) => {
    console.log("setting delay");
    this.setState({
      delay: delay,
    });
  };

  //Reset the list of image URLs
  resetImages = () => {
    this.setState({
      urlList: [],
      width: defaultWidth,
      height: defaultHeight,
    });
  };

  render() {
    const urlList = this.state.urlList;

    return (
      <div className={styles.container}>
        <h1>Create a GIF - Demo</h1>
        <div>
          <p>
            This tool allows a user to upload images, see a preview of their
            GIF, and convert those images into a GIF montage. This version of
            the GIF maker has been implemented as a demo for the Kapwing design
            interview problem.
          </p>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Upload handleFinish={this.handleFinish} />
          <button onClick={this.resetImages}> Reset images </button>
        </div>

        <br></br>
        <DelayInput delay={this.state.delay} handleChange={this.setDelay} />
        <SizeInput
          width={this.state.width}
          height={this.state.height}
          initWidth={this.state.initWidth}
          initHeight={this.state.initHeight}
          handleChange={this.setNewDimentions}
        />

        {
          /* GIF maker implementation starts here */
          urlList.length > 0 ? (
            <>
              <img
                onLoad={this.setInitDimentions}
                alt=""
                src={urlList[0]}
                style={{ display: "none" }}
              />

              <GifPreview
                urlList={urlList}
                delay={this.state.delay}
                width={this.state.width}
                height={this.state.height}
                background_color={this.state.background_color}
                transparent={this.state.transparent}
              />
              <GifCreate
                urlList={urlList}
                delay={this.state.delay}
                width={this.state.width}
                height={this.state.height}
                background_color={this.state.background_color}
                transparent={this.state.transparent}
              />
            </>
          ) : null
        }
      </div>
    );
  }
}

export default GifMaker;
export { MAX_LENGTH };
