import React, { Component } from "react";
import styles from "./SizeInput.module.scss";
import { MAX_LENGTH } from "../GifMaker.jsx";

/* Component for collecting user input to change the image size
 * assumes the input properties:
 *  - width: the current width of the final GIF
 *  - height: the current height of the final GIF
 *  - initWidth: the initial width based on the first frame
 *  - initHeight: the initial height based on the first frame
 *  - handleChange: function from parent, used to pass along changes
 */
class SizeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: props.width,
      height: props.height,
      keepRatio: true,
      loaded: false,
    };
  }

  changeWidth = (event) => {
    //lock slider if no image has loaded yet
    if (!this.state.loaded) {
      return;
    }

    //validate new width
    var newWidth = event.target.value;
    if (newWidth < 0 || newWidth > MAX_LENGTH) {
      return;
    }

    //Change height to match if we are constraining width/height ratio
    if (this.state.keepRatio) {
      var newHeight = (newWidth * this.props.height) / this.props.width;
      if (newHeight > MAX_LENGTH) {
        return;
      }
      this.setState({
        height: newHeight,
      });
    }
    this.setState({
      width: newWidth,
    });
  };

  changeHeight = (event) => {
    //lock slider if no image has loaded yet
    if (!this.state.loaded) {
      return;
    }

    //validare new height
    var newHeight = event.target.value;
    if (newHeight < 0 || newHeight > MAX_LENGTH) {
      return;
    }

    //Change width to match if we are constraining width/height ratio
    if (this.state.keepRatio) {
      var newWidth = (newHeight * this.props.width) / this.props.height;
      if (newWidth > MAX_LENGTH) {
        return;
      }
      this.setState({
        width: newWidth,
      });
    }

    this.setState({
      height: newHeight,
    });
  };

  toggleRatio = () => {
    this.setState((state) => ({
      keepRatio: !state.keepRatio,
    }));
  };

  //Set the height and width back to their initial values
  setToInitial = () => {
    this.setState({
      width: this.props.initWidth,
      height: this.props.initHeight,
    });
  };

  //pass changes in height/width back to the parent
  submitChanges = () => {
    //check that height and width are valid numbers
    if (this.state.height > 0 && this.state.width > 0) {
      this.props.handleChange(this.state.width, this.state.height);
    }
  };

  //Run upon updates to the component's state or properties
  componentDidUpdate(prevProps) {
    //Change the slider positions when the height and width values load from outside
    if (
      prevProps.width !== this.props.width ||
      prevProps.height !== this.props.height
    ) {
      if (
        this.props.width === this.props.initWidth &&
        this.props.height === this.props.initHeight
      ) {
        this.setState({
          width: this.props.initWidth,
          height: this.props.initHeight,
          loaded: true,
        });
      }
    }

    //Otherwise, if we've changed width or height, pass them to the parent
    else if (
      this.props.width !== this.state.width ||
      this.props.height !== this.state.height
    ) {
      this.submitChanges();
    } else {
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <label>
          width
          <input
            type="range"
            min="1"
            max={MAX_LENGTH}
            value={this.state.width}
            onChange={this.changeWidth}
          />
          <input
            type="number"
            value={Math.round(this.state.width)}
            onChange={this.changeWidth}
            className={styles.inputBox}
          />
        </label>
        <br></br>

        <label>
          height
          <input
            type="range"
            min="1"
            max={MAX_LENGTH}
            value={this.state.height}
            onChange={this.changeHeight}
          />
          <input
            type="number"
            value={Math.round(this.state.height)}
            onChange={this.changeHeight}
            className={styles.inputBox}
          />
        </label>
        <br></br>

        <label>
          {" "}
          Constrain width-to-height ratio
          <input
            type="checkbox"
            onChange={this.toggleRatio}
            checked={this.state.keepRatio}
          />
        </label>
        <br></br>
        <input
          type="button"
          value="Set height/width to initial values"
          onClick={this.setToInitial}
        />
      </div>
    );
  }
}

export default SizeInput;
