import React, { Component } from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Home from "pages/Home.jsx";
import GifMaker from "components/gif/GifMaker.jsx";
import Grayscale from "components/grayscale/Grayscale.jsx";
import Subtitles from "components/subtitles/Subtitles.jsx";
import Transcription from "components/transcription/Transcription.jsx";
import styles from "./App.module.scss";

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <div className={styles.menu}>
            <div className={styles.menuItem}>
              <NavLink to="/" activeClassName={styles.active} exact>
                Home
              </NavLink>
            </div>
            <div className={styles.menuItem}>
              <NavLink to="/grayscale" activeClassName={styles.active}>
                Grayscale
              </NavLink>
            </div>
            <div className={styles.menuItem}>
              <NavLink to="/gif-maker" activeClassName={styles.active}>
                GIF Maker
              </NavLink>
            </div>
            <div className={styles.menuItem}>
              <NavLink to="/subtitles" activeClassName={styles.active}>
                Video Subtitles
              </NavLink>
            </div>
            <div className={styles.menuItem}>
              <NavLink to="/transcription" activeClassName={styles.active}>
                Transcription API
              </NavLink>
            </div>
          </div>
          <Route exact path="/" component={Home} />
          <Route path="/gif-maker" component={GifMaker} />
          <Route path="/grayscale" component={Grayscale} />
          <Route path="/subtitles" component={Subtitles} />
          <Route path="/transcription" component={Transcription} />
        </>
      </Router>
    );
  }
}

export default App;
