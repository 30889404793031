import React, { Component } from "react";
import styles from "./SizeInput.module.scss";

/* Component for collecting user input to change the animation speed
 * assumes the input properties:
 *  - delay: the current animation delay of the final GIF
 *  - handleChange: function from parent, used to pass along the selected speed
 */

const minSpeed = 0.5;
const maxSpeed = 10;

class DelayInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speed: 1 / props.delay,
    };
  }

  changeSpeed = (event) => {
    var newSpeed = event.target.value;
    if (newSpeed >= minSpeed && newSpeed <= maxSpeed) {
      this.setState({
        speed: event.target.value,
      });
    }
  };

  //Run upon updates to the component's state or properties
  componentDidUpdate(prevProps) {
    //If we've changed the delay, pass it on to parent
    if (this.props.delay !== 1 / this.state.speed) {
      this.props.handleChange(1 / this.state.speed);
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <label>
          Animation Speed (fps)
          <input
            type="range"
            min={minSpeed}
            max={maxSpeed}
            value={this.state.speed}
            onChange={this.changeSpeed}
          />
          <input
            type="number"
            value={this.state.speed}
            onChange={this.changeSpeed}
            className={styles.inputBox}
          />
        </label>
      </div>
    );
  }
}

export default DelayInput;
