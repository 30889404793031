import React, { Component } from "react";
import styles from "./GifPreview.module.scss";

/* Component representing the GIF preview
 * assumes the input properties:
 *  - urlList: JavaScript array of image URLs for the component images in the gif
 *  - delay: the delay in seconds between displaying each image
 *  - width: the width of the final GIF
 *  - height: the height of the final GIF
 *  - background_color: The color behind the final GIF
 *  - transparent: 1 if the GIF background is transparent, 0 otherwise
 */

class GifPreview extends Component {
  //Use state to keep track of which image to display
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      backgroundStyle: {},
    };
  }

  //change which image we display
  changeFrame() {
    this.setState((state, props) => ({
      index: (state.index + 1) % props.urlList.length,
    }));
  }

  //Set up timer to cycle through images
  setTimer() {
    this.timerID = setInterval(
      () => this.changeFrame(),
      1000 * this.props.delay
    );
  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  componentDidUpdate(prevProps) {
    //Change the background style when its related properties change
    if (
      prevProps.width !== this.props.width ||
      prevProps.height !== this.props.height ||
      prevProps.backgroundColor !== this.props.backgroundColor ||
      prevProps.transparent !== this.props.transparent
    ) {
      this.setState({
        backgroundStyle: this.backgroundStyle(),
      });
    }

    //Change timer if delay has changed
    if (prevProps.delay !== this.props.delay) {
      clearInterval(this.timerID);
      this.setTimer();
    }
  }

  //return object representing the CSS style of the gif background
  backgroundStyle() {
    return {
      width: parseInt(this.props.width),
      height: parseInt(this.props.height),
      backgroundColor:
        this.props.transparent === 1
          ? "transparent"
          : this.props.background_color,
      position: "relative",
    };
  }

  //return list of image elements, where only the current index is visible
  imageList() {
    var imgElements = [];
    for (var i = 0; i < this.props.urlList.length; i++) {
      const url = this.props.urlList[i];
      const imgElement = (
        <img
          src={url}
          key={i + url}
          alt="GIF Preview"
          className={this.state.index === i ? styles.image : styles.invisible}
        />
      );
      imgElements.push(imgElement);
    }
    return imgElements;
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.header}>Preview of final GIF:</div>
        <div style={this.state.backgroundStyle}>{this.imageList()}</div>
      </div>
    );
  }
}

export default GifPreview;
