import React from "react";
import styles from "./Transcription.module.scss";

const Transcription = () => {
  return (
    <div className={styles.container}>
      <h2>Transcription API Documentation</h2>
      <div className={styles.text}>
        <p>
          The Kapwing Interview server provides a limited transcription API for
          the purposes of the transcription interview. Please see below for the
          API endpoints and sample requests as well as responses.
        </p>
        <h3>Creating a transcription</h3>
        <p>
          The interview API can be used to kick off a transcription job for a
          given video. You will need a URL to the video that you would like to
          transcribe. Currently, the transcription works only for a set of
          predefined videos. Then, send a PUT request to the following endpoint
          with your URL:
        </p>
        <div className={styles.endpoint}>
          <span className={styles.put}>PUT</span>{" "}
          <span className={styles.request}>
            https://interview.kapwing.com/api/transcription?url=YOUR_URL
          </span>
        </div>
        <p>
          This endpoint will return a job ID. You can use your job ID to track
          the progress and status of your transcription job.
        </p>
        <h3>Getting the transcription</h3>
        <p>
          Once a transcription job is started, you can get the job progress and
          transcription by sending a request to the following endpoint:
        </p>
        <div className={styles.endpoint}>
          <span className={styles.put}>GET</span>{" "}
          <span className={styles.request}>
            https://interview.kapwing.com/api/transcription?jobId=JOB_ID_HERE
          </span>
        </div>
        <p>
          This endpoint will return the job status, progress, and transcription
          if the job is complete. The transcription will return if the status of
          the job is "done".
        </p>
      </div>
    </div>
  );
};

export default Transcription;
